import { message } from 'antd';
import { useEffect, useState } from 'react';
import { uploadEntityPhoto } from '../waypoint-requests';
import {
    AttributeValue,
    PropertyDetailsProps,
    PropertyType,
    SelectedDataLevel,
} from 'waypoint-types';
import { useGetAttributes } from './data-access/useGetAttributes';
import { useGetSelectedFilteredEntityCodes } from './useGetSelectedFilteredEntityCodes';
import {
    useGetClientAndEntityAttributeValues,
    useGetDataAvailabilityDate,
    useGetLeasesPerformanceOverview,
    useGetPropertyValuation,
    useGetValuations,
    useGetValueAndDebt,
} from 'waypoint-hooks';
import { stringSort } from 'utils/tables/sorters';
import {
    PropertyDetailAttributeEnum,
    propertyProfileGavSources,
} from 'components/propertyProfile/PropertyProfileConstants';
import { KPIMode } from 'components/propertyProfile/PropertyProfileUtils';
import { getDateRangeForPeriod } from 'waypoint-utils';
import moment from 'moment';

interface UsePropertyInformationParams {
    entityCode: string;
    propertyInfo?: PropertyType;
    periodSelectedTiming?: string;
    valueDebtPeriod?: Date;
    selectedDataLevel: SelectedDataLevel;
}

export const usePropertyInformation = ({
    entityCode,
    selectedDataLevel,
    propertyInfo,
    periodSelectedTiming,
    valueDebtPeriod,
}: UsePropertyInformationParams) => {
    const [isImageError, setImageError] = useState(false);
    const [isImageFocused, setImageFocused] = useState(false);
    const [entityPhoto, setEntityPhoto] = useState<string | null>(null);
    const [photoIsUploading, setPhotoIsUploading] = useState(false);
    const [localImage, setLocalImage] = useState<any | null>(null);

    const [selectedAmenityValues, setSelectedAmenityValues] = useState<
        string[]
    >([]);
    const [entityAmenities, setEntityAmenities] = useState<AttributeValue[]>(
        [],
    );
    const [clientAmenityOptions, setClientAmenityOptions] = useState<string[]>(
        [],
    );

    const [portfolioManager, setPortfolioManager] = useState<AttributeValue[]>(
        [],
    );

    const [assetManager, setAssetManager] = useState<AttributeValue[]>([]);

    const [analyst, setAnalyst] = useState<AttributeValue[]>([]);

    const [propertyManager, setPropertyManager] = useState<AttributeValue[]>(
        [],
    );

    const [managementCompany, setManagementCompany] = useState<
        AttributeValue[]
    >([]);

    const [clientTeamOptions, setClientTeamOptions] = useState<string[]>([]);

    const [selectedPortfolioManager, setSelectedPortfolioManager] = useState<
        string[]
    >([]);

    const [selectedAssetManager, setSelectedAssetManager] = useState<string[]>(
        [],
    );

    const [selectedAnalyst, setSelectedAnalyst] = useState<string[]>([]);

    const [selectedPropertyManager, setSelectedPropertyManager] = useState<
        string[]
    >([]);

    const [selectedManagementCompany, setSelectedManagementCompany] = useState<
        string[]
    >([]);

    const [clientParkingSpaces, setClientParkingSpaces] = useState<
        number | undefined
    >(undefined);

    const [clientParkingNotes, setClientParkingNotes] = useState<string>('');

    const [debtNotes, setDebtNotes] = useState<string>('');

    const [clientPurchasePrice, setClientPurchasePrice] = useState<
        number | undefined
    >(undefined);

    const [clientPurchaseDate, setClientPurchaseDate] = useState<string>('');

    const [clientKpiPreferences, setClientKpiPreferences] = useState<string>(
        KPIMode.PSF,
    );

    const [clientTimingPreferences, setClientTimingPreferences] =
        useState<string>('ytd');

    const [clientYearBuilt, setClientYearBuilt] = useState<string>('');

    const [clientLastRenovated, setClientLastRenovated] = useState<string>('');

    const [clientSiteArea, setClientSiteArea] = useState<number | undefined>(
        undefined,
    );

    const [clientFinancialYearEnding, setClientFinancialYearEnding] =
        useState<string>('');

    const [clientCertificationOptions, setClientCertificationOptions] =
        useState<string[]>([]);

    const [selectedCertificationValues, setSelectedCertificationValues] =
        useState<string[]>([]);

    const [entityCertifications, setEntityCertifications] = useState<
        AttributeValue[]
    >([]);

    const [acquisitionCapRate, setAcquisitionCapRate] = useState<
        number | undefined
    >(undefined);

    const [acquisitionDiscountRate, setAcquisitionDiscountRate] = useState<
        number | undefined
    >(undefined);

    const [uwIRR, setUwIRR] = useState<number | undefined>(undefined);

    const [holdPeriod, setHoldPeriod] = useState<number | undefined>(undefined);

    const [levered, setLevered] = useState<boolean>(true);

    const [lender, setLender] = useState<string>('');

    const [loanType, setLoanType] = useState<string>('');

    const [clientRateType, setClientRateType] = useState<string>('');

    const [fixedInterestRate, setFixedInterestRate] = useState<
        number | undefined
    >(undefined);

    const [floatingIndex, setFloatingIndex] = useState<string>('');

    const [interestRateSpread, setInterestRateSpread] = useState<
        number | undefined
    >(undefined);

    const [floors, setFloors] = useState<number | undefined>(undefined);

    const [buildingCount, setBuildingCount] = useState<number | undefined>(
        undefined,
    );

    const [constructionType, setConstructionType] = useState<string>('');

    const [sprinklers, setSprinklers] = useState<string>('');

    const [smokeDetectors, setSmokeDetectors] = useState<boolean | undefined>(
        undefined,
    );

    const [debtMaturityDate, setDebtMaturityDate] = useState<string>('');

    const [propertyDetailsModalIsOpen, setPropertyDetailsModalIsOpen] =
        useState<boolean>(false);

    const [periodSelected, setPeriodSelected] = useState<Date | null>(null);

    const [gavSource, setGavSource] = useState<propertyProfileGavSources>(
        propertyProfileGavSources.BALANCE_SHEET,
    );

    const [leasePropertyData, setLeasePropertyData] = useState<
        PropertyDetailsProps | undefined
    >(undefined);

    const { data: asOfDate, isLoading: isLoadingAsOfDate } =
        useGetDataAvailabilityDate([entityCode]);

    const { data: propertyValuations } = useGetPropertyValuation(entityCode);

    const { data: valuations } = useGetValuations(entityCode);

    const getTiming = () => {
        if (periodSelectedTiming) {
            return periodSelectedTiming;
        }

        return clientTimingPreferences;
    };

    const getSelectedPeriod = () => {
        if (valueDebtPeriod) {
            return valueDebtPeriod;
        }

        if (periodSelected) {
            return periodSelected;
        }

        return asOfDate ?? new Date();
    };

    const valuationForThePeriod =
        propertyValuations?.find((item) => {
            return (
                moment(item.period).format('yyyy-MM') ===
                moment(getSelectedPeriod()).format('yyyy-MM')
            );
        })?.valuation_id ?? '';

    const selectedValuation =
        valuations?.valuations.find((v) => v.id === valuationForThePeriod)
            ?.value ?? 0;

    const [start, end] = getDateRangeForPeriod(
        getTiming(),
        getSelectedPeriod(),
    );

    const {
        data: valueAndDebt,
        isLoading: isLoadingValueAndDebt,
        mutate: mutateValueAndDebt,
    } = useGetValueAndDebt(
        [entityCode],
        [start, end],
        selectedDataLevel,
        isLoadingAsOfDate,
    );

    const {
        data: clientPropertyAttributes,
        mutate: mutateClientPropertyAttributes,
    } = useGetClientAndEntityAttributeValues(
        [
            PropertyDetailAttributeEnum.Amenities,
            PropertyDetailAttributeEnum.NumberOfSpaces,
            PropertyDetailAttributeEnum.ParkingNotes,
            PropertyDetailAttributeEnum.PurchasePrice,
            PropertyDetailAttributeEnum.PurchaseDate,
            PropertyDetailAttributeEnum.KpiPreferences,
            PropertyDetailAttributeEnum.YearBuilt,
            PropertyDetailAttributeEnum.LastRenovated,
            PropertyDetailAttributeEnum.SiteArea,
            PropertyDetailAttributeEnum.Certifications,
            PropertyDetailAttributeEnum.FinancialYearEnding,
            PropertyDetailAttributeEnum.ValueDebtPeriodTiming,
            PropertyDetailAttributeEnum.GavSource,
            PropertyDetailAttributeEnum.FixedInterestRate,
            PropertyDetailAttributeEnum.DebtNotes,
            PropertyDetailAttributeEnum.UWIRR,
            PropertyDetailAttributeEnum.UWHoldPeriod,
            PropertyDetailAttributeEnum.Floors,
            PropertyDetailAttributeEnum.BuildingCount,
            PropertyDetailAttributeEnum.InterestRateSpread,
            PropertyDetailAttributeEnum.ConstructionType,
            PropertyDetailAttributeEnum.Sprinklers,
            PropertyDetailAttributeEnum.SmokeDetectors,
            PropertyDetailAttributeEnum.Levered,
            PropertyDetailAttributeEnum.BuildingDimensions,
            PropertyDetailAttributeEnum.LoanType,
            PropertyDetailAttributeEnum.IndustrialLoadingDocks,
            PropertyDetailAttributeEnum.AcquisitionDiscountRate,
            PropertyDetailAttributeEnum.RateType,
            PropertyDetailAttributeEnum.FloatingIndex,
            PropertyDetailAttributeEnum.DebtMaturityDate,
            PropertyDetailAttributeEnum.OwnershipEntity,
            PropertyDetailAttributeEnum.AcquisitionCapRate,
            PropertyDetailAttributeEnum.Lender,
            PropertyDetailAttributeEnum.PortfolioManager,
            PropertyDetailAttributeEnum.AssetManager,
            PropertyDetailAttributeEnum.Analyst,
            PropertyDetailAttributeEnum.ManagementCompany,
            PropertyDetailAttributeEnum.PropertyManager,
        ],
        true,
    );

    const onChangePeriodSelected = async (date: Date | null) => {
        setPeriodSelected(date);
        await mutateValueAndDebt(valueAndDebt, true);
    };

    const onSetSelectedAmenityValues = (values: string[]) => {
        setSelectedAmenityValues(values.sort((a, b) => stringSort(b, a)));
    };

    const onSetTeamValues = (
        setTeamValues: (value: string[]) => void,
        values: string[],
    ) => {
        setTeamValues(values.sort((a, b) => stringSort(b, a)));
    };

    const onSetSelectedCertificationValues = (values: string[]) => {
        setSelectedCertificationValues(values.sort((a, b) => stringSort(b, a)));
    };

    const extractEntityAttributes = (
        attributes: AttributeValue[],
        entityCode: string,
        attribute: string,
    ) =>
        attributes.filter(
            (a) =>
                a?.attributeDefinition?.attribute_code === attribute &&
                a?.entity_code === entityCode,
        );

    const getExtractedEntityAttributes = (): Map<string, any> => {
        const data = new Map();
        const attributes = clientPropertyAttributes ?? [];
        for (const key of Object.values(PropertyDetailAttributeEnum)) {
            const propertyValue = extractEntityAttributes(
                attributes,
                entityCode,
                key,
            );
            data.set(key, propertyValue);
        }
        return data;
    };

    const getSortedUniqueValues = (attributes: AttributeValue[]) =>
        [...new Set(attributes.map((ca) => ca.value))].sort((a, b) =>
            stringSort(b, a),
        );

    const setAttributesOnState = () => {
        const extractedData = getExtractedEntityAttributes();

        const amenities = extractedData.get(
            PropertyDetailAttributeEnum.Amenities,
        );
        if (amenities !== undefined) {
            setEntityAmenities(amenities);
            onSetSelectedAmenityValues(amenities.map((a: any) => a.value));
        }

        const sortedUniqueClientAmenities = getSortedUniqueValues(
            clientPropertyAttributes?.filter(
                (a) =>
                    a.attributeDefinition?.attribute_code ===
                    PropertyDetailAttributeEnum.Amenities,
            ) ?? [],
        );
        setClientAmenityOptions(sortedUniqueClientAmenities);

        const sortedUniqueClientTeamValues = getSortedUniqueValues(
            clientPropertyAttributes?.filter(
                (a) =>
                    a.attributeDefinition?.attribute_code ===
                        PropertyDetailAttributeEnum.PropertyManager ||
                    a.attributeDefinition?.attribute_code ===
                        PropertyDetailAttributeEnum.AssetManager ||
                    a.attributeDefinition?.attribute_code ===
                        PropertyDetailAttributeEnum.PortfolioManager ||
                    a.attributeDefinition?.attribute_code ===
                        PropertyDetailAttributeEnum.Analyst ||
                    a.attributeDefinition?.attribute_code ===
                        PropertyDetailAttributeEnum.ManagementCompany,
            ) ?? [],
        );
        setClientTeamOptions(sortedUniqueClientTeamValues);

        const numberOfSpaces = extractedData.get(
            PropertyDetailAttributeEnum.NumberOfSpaces,
        );
        if (numberOfSpaces.length) {
            setClientParkingSpaces(parseInt(numberOfSpaces[0]?.value ?? '0'));
        }

        const parkingNotes = extractedData.get(
            PropertyDetailAttributeEnum.ParkingNotes,
        );
        if (parkingNotes.length) {
            setClientParkingNotes(parkingNotes[0]?.value ?? '');
        }

        const debtNotes = extractedData.get(
            PropertyDetailAttributeEnum.DebtNotes,
        );
        if (debtNotes.length) {
            setDebtNotes(debtNotes[0]?.value ?? '');
        }

        const purchasePrice = extractedData.get(
            PropertyDetailAttributeEnum.PurchasePrice,
        );
        if (purchasePrice.length) {
            setClientPurchasePrice(
                parseInt(purchasePrice[0]?.value ?? undefined),
            );
        }

        const purchaseDate = extractedData.get(
            PropertyDetailAttributeEnum.PurchaseDate,
        );
        if (purchaseDate.length) {
            setClientPurchaseDate(purchaseDate[0]?.value ?? '');
        }

        const kpiPreferences = extractedData.get(
            PropertyDetailAttributeEnum.KpiPreferences,
        );
        if (kpiPreferences.length) {
            setClientKpiPreferences(kpiPreferences[0]?.value ?? KPIMode.PSF);
        }

        const timingPreferences = extractedData.get(
            PropertyDetailAttributeEnum.ValueDebtPeriodTiming,
        );

        if (timingPreferences.length) {
            setClientTimingPreferences(timingPreferences[0]?.value ?? 'ytd');
        }

        const yearBuilt = extractedData.get(
            PropertyDetailAttributeEnum.YearBuilt,
        );
        if (yearBuilt.length) {
            setClientYearBuilt(yearBuilt[0]?.value ?? '');
        }

        const lastRenovated = extractedData.get(
            PropertyDetailAttributeEnum.LastRenovated,
        );
        if (lastRenovated.length) {
            setClientLastRenovated(lastRenovated[0]?.value ?? '');
        }

        const siteArea = extractedData.get(
            PropertyDetailAttributeEnum.SiteArea,
        );
        if (siteArea.length) {
            setClientSiteArea(siteArea[0]?.value ?? '0');
        }

        const financialYearEnding = extractedData.get(
            PropertyDetailAttributeEnum.FinancialYearEnding,
        );
        if (financialYearEnding.length) {
            setClientFinancialYearEnding(financialYearEnding[0]?.value ?? '');
        }

        const certifications = extractedData.get(
            PropertyDetailAttributeEnum.Certifications,
        );
        if (certifications !== undefined) {
            setEntityCertifications(certifications);
            onSetSelectedCertificationValues(
                certifications.map((a: any) => a.value),
            );
        }

        const gavSource = extractedData.get(
            PropertyDetailAttributeEnum.GavSource,
        );

        if (gavSource.length) {
            setGavSource(
                gavSource[0].value ?? propertyProfileGavSources.BALANCE_SHEET,
            );
        }

        const sortedUniqueClientCertifications = getSortedUniqueValues(
            clientPropertyAttributes?.filter(
                (a) =>
                    a.attributeDefinition?.attribute_code ===
                    PropertyDetailAttributeEnum.Certifications,
            ) ?? [],
        );
        setClientCertificationOptions(sortedUniqueClientCertifications);

        const acquisitionCapRate = extractedData.get(
            PropertyDetailAttributeEnum.AcquisitionCapRate,
        );

        if (acquisitionCapRate.length) {
            setAcquisitionCapRate(acquisitionCapRate[0].value ?? 0);
        }

        const acquisitionDiscountRate = extractedData.get(
            PropertyDetailAttributeEnum.AcquisitionDiscountRate,
        );

        if (acquisitionDiscountRate.length) {
            setAcquisitionDiscountRate(acquisitionDiscountRate[0].value ?? 0);
        }

        const uwIRR = extractedData.get(PropertyDetailAttributeEnum.UWIRR);

        if (uwIRR.length) {
            setUwIRR(uwIRR[0].value ?? 0);
        }

        const holdPeriod = extractedData.get(
            PropertyDetailAttributeEnum.UWHoldPeriod,
        );

        if (holdPeriod.length) {
            setHoldPeriod(holdPeriod[0].value ?? '');
        }

        const levered = extractedData.get(PropertyDetailAttributeEnum.Levered);

        if (levered.length) {
            setLevered(levered[0].value === 'true' ?? false);
        }

        const lender = extractedData.get(PropertyDetailAttributeEnum.Lender);

        if (lender.length) {
            setLender(lender[0].value ?? '');
        }

        const loanType = extractedData.get(
            PropertyDetailAttributeEnum.LoanType,
        );

        if (loanType.length) {
            setLoanType(loanType[0].value ?? '');
        }

        const rateType = extractedData.get(
            PropertyDetailAttributeEnum.RateType,
        );

        if (rateType.length) {
            setClientRateType(rateType[0].value ?? '');
        }

        const fixedInterestRate = extractedData.get(
            PropertyDetailAttributeEnum.FixedInterestRate,
        );

        if (fixedInterestRate.length) {
            setFixedInterestRate(fixedInterestRate[0].value ?? 0);
        }

        const floatingIndex = extractedData.get(
            PropertyDetailAttributeEnum.FloatingIndex,
        );

        if (floatingIndex.length) {
            setFloatingIndex(floatingIndex[0].value ?? '');
        }

        const interestRateSpread = extractedData.get(
            PropertyDetailAttributeEnum.InterestRateSpread,
        );

        if (interestRateSpread.length) {
            setInterestRateSpread(interestRateSpread[0].value ?? 0);
        }

        const floors = extractedData.get(PropertyDetailAttributeEnum.Floors);

        if (floors.length) {
            setFloors(floors[0].value ?? 0);
        }

        const buildingCount = extractedData.get(
            PropertyDetailAttributeEnum.BuildingCount,
        );

        if (buildingCount.length) {
            setBuildingCount(buildingCount[0].value ?? 0);
        }

        const constructionType = extractedData.get(
            PropertyDetailAttributeEnum.ConstructionType,
        );

        if (constructionType.length) {
            setConstructionType(constructionType[0].value ?? '');
        }

        const sprinklers = extractedData.get(
            PropertyDetailAttributeEnum.Sprinklers,
        );

        if (sprinklers.length) {
            setSprinklers(sprinklers[0].value ?? '');
        }

        const smokeDetectors = extractedData.get(
            PropertyDetailAttributeEnum.SmokeDetectors,
        );

        if (smokeDetectors.length) {
            setSmokeDetectors(smokeDetectors[0].value === 'true' ?? false);
        }

        const debtMaturityDate = extractedData.get(
            PropertyDetailAttributeEnum.DebtMaturityDate,
        );

        if (debtMaturityDate.length) {
            setDebtMaturityDate(debtMaturityDate[0].value ?? '');
        }

        const portfolioManager = extractedData.get(
            PropertyDetailAttributeEnum.PortfolioManager,
        );

        if (portfolioManager !== undefined) {
            setPortfolioManager(portfolioManager);
            onSetTeamValues(
                setSelectedPortfolioManager,
                portfolioManager.map((a: any) => a.value),
            );
        }

        const assetManager = extractedData.get(
            PropertyDetailAttributeEnum.AssetManager,
        );

        if (assetManager !== undefined) {
            setAssetManager(assetManager);
            onSetTeamValues(
                setSelectedAssetManager,
                assetManager.map((a: any) => a.value),
            );
        }

        const analyst = extractedData.get(PropertyDetailAttributeEnum.Analyst);

        if (analyst !== undefined) {
            setAnalyst(analyst);
            onSetTeamValues(
                setSelectedAnalyst,
                analyst.map((a: any) => a.value),
            );
        }

        const propertyManager = extractedData.get(
            PropertyDetailAttributeEnum.PropertyManager,
        );

        if (propertyManager !== undefined) {
            setPropertyManager(propertyManager);
            onSetTeamValues(
                setSelectedPropertyManager,
                propertyManager.map((a: any) => a.value),
            );
        }

        const managementCompany = extractedData.get(
            PropertyDetailAttributeEnum.ManagementCompany,
        );

        if (managementCompany !== undefined) {
            setManagementCompany(managementCompany);
            onSetTeamValues(
                setSelectedManagementCompany,
                managementCompany.map((a: any) => a.value),
            );
        }
    };

    useEffect(() => {
        setAttributesOnState();
    }, [clientPropertyAttributes, propertyDetailsModalIsOpen]);

    // const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    const { data: allAttributes } = useGetAttributes([entityCode]);

    const { data: leasesData, isLoading: isLoadingPropertyDetails } =
        useGetLeasesPerformanceOverview([entityCode], selectedDataLevel);

    useEffect(() => {
        setLeasePropertyData(
            leasesData?.find((property) => property.entity_code === entityCode),
        );
    }, [
        leasesData?.length,
        entityCode,
        selectedDataLevel.percentageType,
        selectedDataLevel.stakeholder,
    ]);

    useEffect(() => {
        const isValidImage = async () => {
            const photoUrl = propertyInfo?.entityPhoto;
            const photo = await checkIfImageIsCorrect(photoUrl ?? '');

            if (!photo) {
                setImageError(true);
            }

            if (!entityPhoto) {
                setEntityPhoto(photoUrl ?? null);
            }
        };

        if (propertyInfo?.entityPhoto) {
            isValidImage();
        }
    }, [propertyInfo]);

    const checkIfImageIsCorrect = (url: string) => {
        if (!url || typeof url !== 'string') {
            return Promise.resolve(false);
        }

        return new Promise((resolve) => {
            const imgElement = new Image();

            imgElement.addEventListener('load', () => resolve(true));
            imgElement.addEventListener('error', () => resolve(false));
            imgElement.src = url;
        });
    };

    const entity =
        allAttributes?.entities?.find((x) => x.entityCode === entityCode) ??
        null;

    const fullAddress = propertyInfo
        ? [
              propertyInfo.street_address,
              propertyInfo.city,
              propertyInfo.state_abbr,
          ]
              .filter((x) => x?.trim().length)
              .join(', ') + ` ${propertyInfo.postal_code ?? ''}`
        : '';

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files ?? [];

        const isJpg =
            file[0].type === 'image/jpeg' || file[0].type === 'image/jpg';
        const isLighterThan20M =
            file[0].size && file[0].size / 1024 / 1024 < 25;

        if (!isJpg) {
            message.error('Wrong format \n You can only upload JPG/JPEG files');

            return;
        }
        if (!isLighterThan20M) {
            message.error('File size too large \n Must be less than 25MB');
            return;
        }

        setPhotoIsUploading(true);

        const photo = new FormData();
        photo.append('upload', file[0]);
        photo.append('name', file[0].name);

        try {
            await uploadEntityPhoto(entityCode, photo);
            const img = file[0];
            setLocalImage(URL.createObjectURL(img));
            message.success('Photo uploaded successfully');
        } catch (error) {
            message.error('Something went wrong uploading the picture');
        }

        setImageError(false);
        setPhotoIsUploading(false);
    };

    const filterByAttributeCode = (attributeCode: string) => {
        return clientPropertyAttributes?.filter(
            (a) =>
                a.attributeDefinition?.attribute_code === attributeCode &&
                a.entity_code === entityCode,
        );
    };

    const propertyDetails = {
        parkingSpaces: filterByAttributeCode(
            PropertyDetailAttributeEnum.NumberOfSpaces,
        ),
        parkingNotes: filterByAttributeCode(
            PropertyDetailAttributeEnum.ParkingNotes,
        ),
        clientAmenities: filterByAttributeCode(
            PropertyDetailAttributeEnum.Amenities,
        ),
        purchasePrice: filterByAttributeCode(
            PropertyDetailAttributeEnum.PurchasePrice,
        ),
        purchaseDate: filterByAttributeCode(
            PropertyDetailAttributeEnum.PurchaseDate,
        ),
        yearBuilt: filterByAttributeCode(PropertyDetailAttributeEnum.YearBuilt),
        lastRenovated: filterByAttributeCode(
            PropertyDetailAttributeEnum.LastRenovated,
        ),
        siteArea: filterByAttributeCode(PropertyDetailAttributeEnum.SiteArea),
        clientCertifications: filterByAttributeCode(
            PropertyDetailAttributeEnum.Certifications,
        ),
        financialYearEnding: filterByAttributeCode(
            PropertyDetailAttributeEnum.FinancialYearEnding,
        ),
        timingPreference: filterByAttributeCode(
            PropertyDetailAttributeEnum.ValueDebtPeriodTiming,
        ),
        gav: filterByAttributeCode(PropertyDetailAttributeEnum.GavSource),
    };

    const valueAndDebtData = {
        ...valueAndDebt,
        gross_value:
            gavSource === propertyProfileGavSources.BALANCE_SHEET
                ? valueAndDebt?.gross_value
                : selectedValuation,
    };

    return {
        ...propertyDetails,
        isLoadingValueAndDebt,
        valueAndDebtData,
        clientPurchasePrice,
        setClientPurchasePrice,
        clientPurchaseDate,
        setClientPurchaseDate,
        clientParkingSpaces,
        setClientParkingSpaces,
        clientParkingNotes,
        setClientParkingNotes,
        debtNotes,
        setDebtNotes,
        isImageError,
        isImageFocused,
        setImageFocused,
        entityPhoto,
        photoIsUploading,
        localImage,
        isLoadingPropertyDetails,
        entity,
        fullAddress,
        handleChange,
        leasePropertyData,
        allAttributes,
        checkIfImageIsCorrect,
        clientPropertyAttributes,
        mutateClientPropertyAttributes,
        selectedAmenityValues,
        onSetSelectedAmenityValues,
        onSetTeamValues,
        clientAmenityOptions,
        setClientAmenityOptions,
        clientTeamOptions,
        selectedPortfolioManager,
        selectedAssetManager,
        selectedAnalyst,
        selectedPropertyManager,
        selectedManagementCompany,
        setSelectedPortfolioManager,
        setSelectedAssetManager,
        setSelectedAnalyst,
        setSelectedPropertyManager,
        setSelectedManagementCompany,
        entityAmenities,
        acquisitionCapRate,
        setAcquisitionCapRate,
        acquisitionDiscountRate,
        setAcquisitionDiscountRate,
        uwIRR,
        setUwIRR,
        holdPeriod,
        setHoldPeriod,
        levered,
        setLevered,
        lender,
        setLender,
        loanType,
        setLoanType,
        clientRateType,
        setClientRateType,
        fixedInterestRate,
        setFixedInterestRate,
        floatingIndex,
        setFloatingIndex,
        interestRateSpread,
        setInterestRateSpread,
        floors,
        setFloors,
        buildingCount,
        setBuildingCount,
        constructionType,
        setConstructionType,
        sprinklers,
        setSprinklers,
        smokeDetectors,
        setSmokeDetectors,
        debtMaturityDate,
        setDebtMaturityDate,
        portfolioManager,
        setPortfolioManager,
        assetManager,
        setAssetManager,
        analyst,
        setAnalyst,
        propertyManager,
        setPropertyManager,
        managementCompany,
        setManagementCompany,
        propertyDetailsModalIsOpen,
        setPropertyDetailsModalIsOpen,
        setClientKpiPreferences,
        clientKpiPreferences,
        setClientYearBuilt,
        clientYearBuilt,
        clientLastRenovated,
        setClientLastRenovated,
        clientSiteArea,
        setClientSiteArea,
        selectedCertificationValues,
        onSetSelectedCertificationValues,
        entityCertifications,
        setEntityCertifications,
        clientCertificationOptions,
        setClientCertificationOptions,
        clientFinancialYearEnding,
        setClientFinancialYearEnding,
        clientTimingPreferences,
        setClientTimingPreferences,
        asOfDate,
        onChangePeriodSelected,
        periodSelected,
        mutateValueAndDebt,
        gavSource,
        setGavSource,
    };
};
