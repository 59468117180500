import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { AttributeDefinition, OptionType } from 'waypoint-types';
import { stringSort } from 'utils/tables/sorters';

export interface AttributesEditableCellTemplateProps {
    value: string | string[];
    setValue: (value: string | string[]) => void;
}

interface AttributesEditableCellProps {
    template: AttributesEditableCellTemplateProps;
    attribute?: AttributeDefinition;
}

export const AttributesEditableSelect = ({
    template,
    attribute,
}: AttributesEditableCellProps) => {
    const [values, setValues] = useState<string[]>([]);
    const [options, setOptions] = useState<OptionType[]>([]);

    useEffect(() => {
        if (attribute?.allowed_values) {
            setOptions(
                attribute.allowed_values.map((allowedValue) => ({
                    label: allowedValue,
                    value: allowedValue,
                })),
            );

            return;
        }

        if (
            !template.value ||
            template.value === '' ||
            (Array.isArray(template.value) && template.value?.[0] === '')
        ) {
            return;
        }

        const templateValues = Array.isArray(template.value)
            ? template.value
            : [template.value];

        const uniqueValues = Array.from(new Set([...templateValues]));
        const uniqueOptions = uniqueValues.map((val) => ({
            label: val,
            value: val,
        }));

        setOptions(uniqueOptions);
    }, [template, template.value, attribute]);

    useEffect(() => {
        const templateValues = Array.isArray(template.value)
            ? template.value
            : [template.value];
        const uniqueValues = Array.from(new Set([...templateValues]));

        setValues(uniqueValues);
    }, [template, template.value]);

    const handleSelect = (selectedValue: string) => {
        if (!attribute?.allowed_values) {
            const updatedOptions = Array.from(
                new Set([
                    ...options,
                    { label: selectedValue, value: selectedValue },
                ]),
            );

            setOptions(updatedOptions);
        }

        if (attribute?.is_multiselect) {
            const updatedValues = Array.isArray(template.value)
                ? [...template.value, selectedValue]
                : [selectedValue];

            const sortedValues = updatedValues.sort((a, b) => stringSort(b, a));
            const values = Array.from(new Set(sortedValues));

            setValues(values);
            template.setValue(values);
        } else {
            setValues([selectedValue]);
            template.setValue([selectedValue]);
        }
    };

    const handleDeselect = (deselectedValue: string) => {
        const updatedValues = Array.isArray(template.value)
            ? template.value.filter((val: any) => val !== deselectedValue)
            : [];

        const sortedValues = updatedValues.sort((a, b) => stringSort(b, a));
        setValues(sortedValues);
        template.setValue(sortedValues);
    };

    return (
        <Select
            mode={
                attribute?.is_multiselect
                    ? attribute.allowed_values
                        ? 'multiple'
                        : 'tags'
                    : undefined
            }
            value={values}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            style={{ width: '100%' }}
            options={options
                .filter((option) => option.value !== '')
                .map((option) => ({
                    key: `${attribute?.id}_${option.value}`,
                    value: option.value,
                    label: option.label,
                }))}
        />
    );
};
