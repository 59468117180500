import { Card } from 'antd';
import { AttributesExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/AttributesExportableGrid';
import { AppFeaturePermissions } from 'contexts';
import { DataGrid } from 'devextreme-react';
import { Column, Paging, DataGridRef } from 'devextreme-react/data-grid';
import { AttributeDefinition } from 'waypoint-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { useGetAttributes } from 'waypoint-hooks';

interface PropertyProfileAttributesTableParams {
    entityAttributes: AttributeDefinition[] | undefined;
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    isPDFExport?: boolean;
}
export const PropertyProfileAttributesTable = ({
    entityAttributes,
    dataGridRef,
    isPDFExport,
}: PropertyProfileAttributesTableParams) => {
    const entityAttributeRows =
        entityAttributes?.reduce<
            { title: string; value: string; key: string; dataIndex: string }[]
        >((result, attr) => {
            return [
                ...result,
                ...(attr.attributeValues?.map(({ value }) => ({
                    title: attr.name,
                    key: attr.attribute_code,
                    dataIndex: attr.attribute_code,
                    value,
                })) ?? []),
            ];
        }, []) ?? [];

    if (isPDFExport) {
        return <AttributesExportableGrid data={entityAttributeRows} />;
    }

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.Attributes}>
            <Card
                title="Property Attributes"
                id="property-attributes"
                style={{ margin: '20px 0' }}
                data-testid="property-detail-attributes-card"
            >
                <DataGrid
                    ref={dataGridRef}
                    dataSource={entityAttributeRows}
                    data-testid="property-detail-attributes-grid"
                    showBorders={true}
                    height="450px"
                >
                    <Paging enabled={false} />
                    <Column
                        caption="Attribute"
                        dataField="title"
                        defaultSortIndex={1}
                        defaultSortOrder="asc"
                    />
                    <Column dataField="value" />
                </DataGrid>
            </Card>
        </PermissionedWrapper>
    );
};
