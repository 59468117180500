import React from 'react';
import {
    Modal,
    Select,
    message,
    Input,
    Radio,
    InputNumber,
    Tabs,
    Form,
    Divider,
    Row,
    Col,
} from 'antd';
import { usePropertyInformation } from 'waypoint-hooks';
import { createOrUpdateEntityAttributeValues } from 'waypoint-requests';
import { DatePicker } from 'waypoint-react';
import {
    AttributeValue,
    PropertyType,
    SelectedDataLevel,
} from 'waypoint-types';
import { UpdateEntityAttributeValuesParams } from 'waypoint-requests/attributes/createOrUpdateEntityAttributeValues';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
    addToParamsIfRequired,
    monthsAsFinancialYearEndings,
} from './PropertyProfileUtils';
import { css } from 'emotion';
import {
    PropertyDetailAttributeEnum,
    propertyProfileGavSources,
} from './PropertyProfileConstants';
import { AppFeaturePermissions, usePermissions } from 'contexts';

const { TextArea } = Input;
const { TabPane } = Tabs;

interface PropertyDetailsEditorProps {
    entityCode: string;
    selectedDataLevel: SelectedDataLevel;
    propertyInfo: PropertyType;
    hasDebtAndValuePropertyInfo: boolean;
}

const modalStyle = css`
    width: 800px !important;
    @media screen and (max-width: 850px) {
        width: 450px !important;
    }
    .ant-modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
            margin: 0 !important;
        }
    }
    .ant-form-item {
        margin-bottom: 18px;
    }
    .ant-modal-body {
        max-height: 600px;
        overflow: none;
    }
`;

export const CLIENT_RATE_TYPE_ENUM = {
    FIXED: 'Fixed',
    FLOATING: 'Floating',
};

const PropertyDetailsEditor = ({
    entityCode,
    selectedDataLevel,
    propertyInfo,
    hasDebtAndValuePropertyInfo,
}: PropertyDetailsEditorProps) => {
    const {
        parkingSpaces,
        parkingNotes,
        purchaseDate,
        purchasePrice,
        siteArea,
        lastRenovated,
        yearBuilt,
        clientCertifications,
        timingPreference,
        clientParkingSpaces,
        setClientParkingSpaces,
        clientParkingNotes,
        setClientParkingNotes,
        debtNotes,
        setDebtNotes,
        clientAmenities,
        acquisitionCapRate,
        setAcquisitionCapRate,
        acquisitionDiscountRate,
        setAcquisitionDiscountRate,
        uwIRR,
        setUwIRR,
        holdPeriod,
        setHoldPeriod,
        levered,
        setLevered,
        loanType,
        setLoanType,
        lender,
        setLender,
        clientRateType,
        setClientRateType,
        fixedInterestRate,
        setFixedInterestRate,
        floors,
        setFloors,
        buildingCount,
        setBuildingCount,
        constructionType,
        setConstructionType,
        sprinklers,
        setSprinklers,
        smokeDetectors,
        setSmokeDetectors,
        floatingIndex,
        setFloatingIndex,
        interestRateSpread,
        setInterestRateSpread,
        debtMaturityDate,
        setDebtMaturityDate,
        portfolioManager,
        assetManager,
        analyst,
        propertyManager,
        managementCompany,
        setSelectedPortfolioManager,
        setSelectedAssetManager,
        setSelectedAnalyst,
        setSelectedPropertyManager,
        setSelectedManagementCompany,
        selectedPortfolioManager,
        selectedAssetManager,
        selectedAnalyst,
        selectedPropertyManager,
        selectedManagementCompany,
        clientPurchasePrice,
        setClientPurchasePrice,
        clientPurchaseDate,
        setClientPurchaseDate,
        clientKpiPreferences,
        setClientKpiPreferences,
        clientSiteArea,
        setClientSiteArea,
        clientLastRenovated,
        setClientLastRenovated,
        clientYearBuilt,
        setClientYearBuilt,
        clientFinancialYearEnding,
        setClientFinancialYearEnding,
        entityAmenities,
        selectedAmenityValues,
        clientAmenityOptions,
        clientTeamOptions,
        onSetSelectedAmenityValues,
        onSetTeamValues,
        entityCertifications,
        selectedCertificationValues,
        clientCertificationOptions,
        onSetSelectedCertificationValues,
        mutateClientPropertyAttributes,
        propertyDetailsModalIsOpen,
        setPropertyDetailsModalIsOpen,
        clientTimingPreferences,
        setClientTimingPreferences,
        mutateValueAndDebt,
        gavSource,
        setGavSource,
    } = usePropertyInformation({
        entityCode,
        selectedDataLevel,
        propertyInfo,
    });
    const { featurePermissions } = usePermissions();

    const rateTypeOptions = [
        {
            label: CLIENT_RATE_TYPE_ENUM.FIXED,
            value: CLIENT_RATE_TYPE_ENUM.FIXED,
        },
        {
            label: CLIENT_RATE_TYPE_ENUM.FLOATING,
            value: CLIENT_RATE_TYPE_ENUM.FLOATING,
        },
    ];

    const commonMutationConfig = {
        rollbackOnError: true,
        populateCache: true,
        revalidate: true,
    };

    const closeModal = () => {
        setPropertyDetailsModalIsOpen(false);
    };

    const handleSaveAttribute = async (
        params: UpdateEntityAttributeValuesParams[],
        attributes: AttributeValue[],
        attributeName: string,
    ) => {
        let updatedAttributes: AttributeValue[] = [];
        try {
            updatedAttributes =
                await createOrUpdateEntityAttributeValues(params);
        } catch (e) {
            message.error(`Failed to update ${attributeName}`);
            return attributes;
        }
        return updatedAttributes;
    };

    const onSaveAllAttributes = async () => {
        const newAmenitiesAttributeValues = selectedAmenityValues.filter(
            (value) => !entityAmenities.map((ea) => ea.value).includes(value),
        );

        const amenitiesIdsToDelete = entityAmenities
            .filter((ea) => !selectedAmenityValues.includes(ea.value))
            .map((ea) => ea.id);

        const amenitiesParams = {
            attribute_codes: [PropertyDetailAttributeEnum.Amenities],
            attribute_values: newAmenitiesAttributeValues,
            deleted_attribute_value_ids: amenitiesIdsToDelete,
            entity_code: entityCode,
            is_standard_attribute: true,
        };

        const newPropertyManager = selectedPropertyManager.filter(
            (value) => !propertyManager.map((ea) => ea.value).includes(value),
        );

        const propertyManagerIdsToDelete = propertyManager
            .filter((ea) => !selectedPropertyManager.includes(ea.value))
            .map((ea) => ea.id);

        const propertyManagerParams = {
            attribute_codes: [PropertyDetailAttributeEnum.PropertyManager],
            attribute_values: newPropertyManager,
            deleted_attribute_value_ids: propertyManagerIdsToDelete,
            entity_code: entityCode,
            is_standard_attribute: true,
        };

        const newPortfolioManager = selectedPortfolioManager.filter(
            (value) => !portfolioManager.map((ea) => ea.value).includes(value),
        );

        const portfolioManagerIdsToDelete = portfolioManager
            .filter((ea) => !selectedPortfolioManager.includes(ea.value))
            .map((ea) => ea.id);

        const portfolioManagerParams = {
            attribute_codes: [PropertyDetailAttributeEnum.PortfolioManager],
            attribute_values: newPortfolioManager,
            deleted_attribute_value_ids: portfolioManagerIdsToDelete,
            entity_code: entityCode,
            is_standard_attribute: true,
        };

        const newAssetManager = selectedAssetManager.filter(
            (value) => !assetManager.map((ea) => ea.value).includes(value),
        );

        const assetManagerIdsToDelete = assetManager
            .filter((ea) => !selectedAssetManager.includes(ea.value))
            .map((ea) => ea.id);

        const assetManagerParams = {
            attribute_codes: [PropertyDetailAttributeEnum.AssetManager],
            attribute_values: newAssetManager,
            deleted_attribute_value_ids: assetManagerIdsToDelete,
            entity_code: entityCode,
            is_standard_attribute: true,
        };

        const newAnalyst = selectedAnalyst.filter(
            (value) => !analyst.map((ea) => ea.value).includes(value),
        );

        const analystIdsToDelete = analyst
            .filter((ea) => !selectedAnalyst.includes(ea.value))
            .map((ea) => ea.id);

        const analystParams = {
            attribute_codes: [PropertyDetailAttributeEnum.Analyst],
            attribute_values: newAnalyst,
            deleted_attribute_value_ids: analystIdsToDelete,
            entity_code: entityCode,
            is_standard_attribute: true,
        };
        const newManagementCompany = selectedManagementCompany.filter(
            (value) => !managementCompany.map((ea) => ea.value).includes(value),
        );

        const managementCompanyIdsToDelete = managementCompany
            .filter((ea) => !selectedManagementCompany.includes(ea.value))
            .map((ea) => ea.id);

        const managementCompanyParams = {
            attribute_codes: [PropertyDetailAttributeEnum.ManagementCompany],
            attribute_values: newManagementCompany,
            deleted_attribute_value_ids: managementCompanyIdsToDelete,
            entity_code: entityCode,
            is_standard_attribute: true,
        };

        const newCertsAttributeValues = selectedCertificationValues.filter(
            (value) => !entityAmenities.map((ea) => ea.value).includes(value),
        );

        const certsIdsToDelete = entityCertifications
            .filter((ea) => !selectedCertificationValues.includes(ea.value))
            .map((ea) => ea.id);

        const certificationsParams = {
            attribute_codes: ['certifications'],
            attribute_values: newCertsAttributeValues,
            deleted_attribute_value_ids: certsIdsToDelete,
            entity_code: entityCode,
            is_standard_attribute: true,
        };

        const attributes = [
            {
                shouldAdd: true,
                attributeValue: clientPurchasePrice ?? 0,
                attributeCode: PropertyDetailAttributeEnum.PurchasePrice,
                entityCode,
            },
            {
                shouldAdd:
                    (purchaseDate?.length ?? 0) > 0 ||
                    clientPurchaseDate !== '',
                attributeValue: clientPurchaseDate,
                attributeCode: PropertyDetailAttributeEnum.PurchaseDate,
                entityCode,
            },
            {
                shouldAdd: true,
                attributeValue: clientParkingSpaces ?? 0,
                attributeCode: PropertyDetailAttributeEnum.NumberOfSpaces,
                entityCode,
            },
            {
                shouldAdd:
                    !clientParkingNotes.length ||
                    (clientParkingNotes.length > 0 &&
                        clientParkingNotes.trim() !== ''),
                attributeValue: clientParkingNotes.trim(),
                attributeCode: PropertyDetailAttributeEnum.ParkingNotes,
                entityCode,
            },
            {
                shouldAdd:
                    !debtNotes.length ||
                    (debtNotes.length > 0 && debtNotes.trim() !== ''),
                attributeValue: debtNotes.trim(),
                attributeCode: PropertyDetailAttributeEnum.DebtNotes,
                entityCode,
            },
            {
                shouldAdd: clientKpiPreferences !== '',
                attributeValue: clientKpiPreferences,
                attributeCode: PropertyDetailAttributeEnum.KpiPreferences,
                entityCode,
            },
            {
                shouldAdd: clientTimingPreferences !== '',
                attributeValue: clientTimingPreferences,
                attributeCode:
                    PropertyDetailAttributeEnum.ValueDebtPeriodTiming,
                entityCode,
            },
            {
                shouldAdd:
                    (yearBuilt?.length ?? 0) > 0 || clientYearBuilt !== '',
                attributeValue: clientYearBuilt,
                attributeCode: PropertyDetailAttributeEnum.YearBuilt,
                entityCode,
            },
            {
                shouldAdd:
                    (lastRenovated?.length ?? 0) > 0 ||
                    clientLastRenovated !== '',
                attributeValue: clientLastRenovated,
                attributeCode: PropertyDetailAttributeEnum.LastRenovated,
                entityCode,
            },
            {
                shouldAdd: true,
                attributeValue: clientSiteArea ?? 0,
                attributeCode: PropertyDetailAttributeEnum.SiteArea,
                entityCode,
            },
            {
                shouldAdd:
                    (clientFinancialYearEnding?.length ?? 0) > 0 ||
                    clientFinancialYearEnding !== '',
                attributeValue: clientFinancialYearEnding ?? 0,
                attributeCode: PropertyDetailAttributeEnum.FinancialYearEnding,
                entityCode,
            },
            {
                shouldAdd: true,
                attributeValue: acquisitionCapRate ?? 0,
                attributeCode: PropertyDetailAttributeEnum.AcquisitionCapRate,
                entityCode,
            },
            {
                shouldAdd: true,
                attributeValue: acquisitionDiscountRate ?? 0,
                attributeCode:
                    PropertyDetailAttributeEnum.AcquisitionDiscountRate,
                entityCode,
            },
            {
                shouldAdd: true,
                attributeValue: uwIRR ?? 0,
                attributeCode: PropertyDetailAttributeEnum.UWIRR,
                entityCode,
            },
            {
                shouldAdd: true,
                attributeValue: holdPeriod ?? 0,
                attributeCode: PropertyDetailAttributeEnum.UWHoldPeriod,
                entityCode,
            },
            {
                shouldAdd: [
                    propertyProfileGavSources.BALANCE_SHEET,
                    propertyProfileGavSources.VALUATIONS,
                ].includes(gavSource),
                attributeValue: gavSource,
                attributeCode: PropertyDetailAttributeEnum.GavSource,
                entityCode,
            },
            {
                shouldAdd: levered !== undefined,
                attributeValue: levered,
                attributeCode: PropertyDetailAttributeEnum.Levered,
                entityCode,
            },
            {
                shouldAdd:
                    !lender.length ||
                    (lender.length > 0 && lender.trim() !== ''),
                attributeValue: lender.trim(),
                attributeCode: PropertyDetailAttributeEnum.Lender,
                entityCode,
            },
            {
                shouldAdd:
                    !loanType.length ||
                    (loanType.length > 0 && loanType.trim() !== ''),
                attributeValue: loanType.trim(),
                attributeCode: PropertyDetailAttributeEnum.LoanType,
                entityCode,
            },
            {
                shouldAdd:
                    clientRateType?.length !== 0 ||
                    clientRateType.trim() !== '',
                attributeValue: clientRateType.trim(),
                attributeCode: PropertyDetailAttributeEnum.RateType,
                entityCode,
            },
            {
                shouldAdd: true,
                attributeValue: fixedInterestRate ?? 0,
                attributeCode: PropertyDetailAttributeEnum.FixedInterestRate,
                entityCode,
            },
            {
                shouldAdd:
                    floatingIndex?.length !== 0 || floatingIndex.trim() !== '',
                attributeValue: floatingIndex.trim(),
                attributeCode: PropertyDetailAttributeEnum.FloatingIndex,
                entityCode,
            },
            {
                shouldAdd: true,
                attributeValue: interestRateSpread ?? 0,
                attributeCode: PropertyDetailAttributeEnum.InterestRateSpread,
                entityCode,
            },
            {
                shouldAdd: true,
                attributeValue: floors ?? 0,
                attributeCode: PropertyDetailAttributeEnum.Floors,
                entityCode,
            },
            {
                shouldAdd: true,
                attributeValue: buildingCount ?? 0,
                attributeCode: PropertyDetailAttributeEnum.BuildingCount,
                entityCode,
            },
            {
                shouldAdd:
                    !constructionType.length ||
                    (constructionType.length > 0 &&
                        constructionType.trim() !== ''),
                attributeValue: constructionType.trim(),
                attributeCode: PropertyDetailAttributeEnum.ConstructionType,
                entityCode,
            },
            {
                shouldAdd:
                    !sprinklers.length ||
                    (sprinklers.length > 0 && sprinklers.trim() !== ''),
                attributeValue: sprinklers.trim(),
                attributeCode: PropertyDetailAttributeEnum.Sprinklers,
                entityCode,
            },
            {
                shouldAdd: smokeDetectors !== undefined,
                attributeValue: smokeDetectors,
                attributeCode: PropertyDetailAttributeEnum.SmokeDetectors,
                entityCode,
            },
            {
                shouldAdd:
                    debtMaturityDate?.length !== 0 ||
                    debtMaturityDate.trim() !== '',
                attributeValue: debtMaturityDate.trim(),
                attributeCode: PropertyDetailAttributeEnum.DebtMaturityDate,
                entityCode,
            },
        ];

        const paramsToPush = attributes.reduce(addToParamsIfRequired, []);

        try {
            let attributes: AttributeValue[] = clientAmenities
                ? [...clientAmenities]
                : [];
            attributes = clientCertifications
                ? [...attributes, ...clientCertifications]
                : attributes;

            const results = await handleSaveAttribute(
                [
                    amenitiesParams,
                    certificationsParams,
                    propertyManagerParams,
                    portfolioManagerParams,
                    assetManagerParams,
                    analystParams,
                    managementCompanyParams,
                    ...paramsToPush,
                ],
                attributes,
                'information',
            );

            mutateClientPropertyAttributes(() => results.flat(), {
                optimisticData: [
                    ...(clientAmenities ?? []),
                    ...(parkingSpaces ?? []),
                    ...(parkingNotes ?? []),
                    ...(purchasePrice ?? []),
                    ...(purchaseDate ?? []),
                    ...(yearBuilt ?? []),
                    ...(lastRenovated ?? []),
                    ...(siteArea ?? []),
                    ...(clientCertifications ?? []),
                    ...(timingPreference ?? []),
                ],
                ...commonMutationConfig,
            });

            mutateValueAndDebt();
            message.success('All attributes updated successfully');
        } catch (e) {
            message.error('Failed to update all attributes');
        }

        closeModal();
    };

    const filteredAmenitiesOptions = clientAmenityOptions.filter(
        (option) => !selectedAmenityValues.includes(option),
    );

    const filterTeamOptions = clientTeamOptions.filter(
        (option) =>
            !selectedPortfolioManager.includes(option) &&
            !selectedAssetManager.includes(option) &&
            !selectedAnalyst.includes(option) &&
            !selectedPropertyManager.includes(option) &&
            !selectedManagementCompany.includes(option),
    );

    const filteredCertsOptions = clientCertificationOptions.filter(
        (option) => !selectedCertificationValues.includes(option),
    );

    const hasPropertyValueAndDebtPermission = !featurePermissions.includes(
        AppFeaturePermissions.PropertyValueDebt,
    );

    return (
        <>
            <div
                data-testid="property-details-editor"
                onClick={() => setPropertyDetailsModalIsOpen(true)}
            >
                <span style={{ marginRight: '10px', cursor: 'pointer' }}>
                    <EditOutlined />
                </span>
            </div>
            <Modal
                data-testid="property-details-modal"
                title={<h1>{propertyInfo.name}</h1>}
                centered
                open={propertyDetailsModalIsOpen}
                className={modalStyle}
                onCancel={() => setPropertyDetailsModalIsOpen(false)}
                okText="Save"
                onOk={() => onSaveAllAttributes()}
            >
                <Tabs type="card">
                    <TabPane tab="Site Information" key="siteInformation">
                        <Form
                            layout={'horizontal'}
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            labelWrap
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                padding: 15,
                            }}
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Year Built">
                                        <DatePicker
                                            picker="year"
                                            data-testid="year-built-input"
                                            style={{ width: '200px' }}
                                            value={
                                                clientYearBuilt
                                                    ? moment(
                                                          clientYearBuilt,
                                                          'YYYY',
                                                      )
                                                    : null
                                            }
                                            onChange={(e) => {
                                                if (e) {
                                                    setClientYearBuilt(
                                                        e.format('YYYY'),
                                                    );
                                                    return;
                                                }
                                                setClientYearBuilt('');
                                            }}
                                            format={'YYYY'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Last Renovated">
                                        <DatePicker
                                            picker="year"
                                            data-testid="last-renovated-input"
                                            style={{ width: '200px' }}
                                            value={
                                                clientLastRenovated
                                                    ? moment(
                                                          clientLastRenovated,
                                                          'YYYY',
                                                      )
                                                    : null
                                            }
                                            onChange={(e) => {
                                                if (e) {
                                                    setClientLastRenovated(
                                                        e.format('YYYY'),
                                                    );
                                                    return;
                                                }
                                                setClientLastRenovated('');
                                            }}
                                            format={'YYYY'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Site Area">
                                        <InputNumber
                                            data-testid="site-area-input"
                                            style={{ width: '200px' }}
                                            min={0}
                                            precision={4}
                                            type="number"
                                            placeholder={'0.0000'}
                                            defaultValue={clientSiteArea ?? 0}
                                            value={clientSiteArea}
                                            onChange={(value) =>
                                                setClientSiteArea(
                                                    value ?? undefined,
                                                )
                                            }
                                            addonAfter={'Acres'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Buildings">
                                        <InputNumber
                                            data-testid="buildings-input"
                                            style={{ width: '200px' }}
                                            min={0}
                                            placeholder={'0'}
                                            type="number"
                                            defaultValue={buildingCount}
                                            value={buildingCount}
                                            onChange={(value) =>
                                                setBuildingCount(
                                                    value ?? undefined,
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Floors">
                                        <InputNumber
                                            data-testid="floors-input"
                                            style={{ width: '200px' }}
                                            min={0}
                                            type="number"
                                            placeholder={'0'}
                                            defaultValue={floors ?? 0}
                                            value={floors}
                                            onChange={(value) =>
                                                setFloors(value ?? undefined)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Parking">
                                        <InputNumber
                                            data-testid="parking-spaces-input"
                                            type="number"
                                            style={{ width: '200px' }}
                                            min={0}
                                            defaultValue={clientParkingSpaces}
                                            value={clientParkingSpaces}
                                            placeholder={'0'}
                                            addonAfter="Spaces"
                                            onChange={(value) =>
                                                setClientParkingSpaces(
                                                    value ?? undefined,
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Parking Notes">
                                        <TextArea
                                            data-testid="parking-notes-textarea"
                                            rows={3}
                                            maxLength={500}
                                            showCount
                                            defaultValue={clientParkingNotes}
                                            value={clientParkingNotes}
                                            onChange={(e) =>
                                                setClientParkingNotes(
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Construction Type">
                                        <TextArea
                                            data-testid="construction-type-textarea"
                                            rows={1}
                                            maxLength={500}
                                            showCount
                                            defaultValue={constructionType}
                                            value={constructionType}
                                            onChange={(e) =>
                                                setConstructionType(
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Sprinklers">
                                        <TextArea
                                            data-testid="sprinklers-textarea"
                                            rows={1}
                                            maxLength={500}
                                            showCount
                                            defaultValue={sprinklers}
                                            value={sprinklers}
                                            onChange={(e) =>
                                                setSprinklers(e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Smoke Detectors">
                                        <Radio.Group
                                            onChange={(e) =>
                                                setSmokeDetectors(
                                                    e.target.value,
                                                )
                                            }
                                            value={smokeDetectors}
                                            defaultValue={smokeDetectors}
                                        >
                                            <Radio
                                                value={true}
                                                style={{
                                                    fontWeight: 'normal',
                                                    fontSize: 13,
                                                    width: 120,
                                                }}
                                            >
                                                Yes
                                            </Radio>
                                            <Radio
                                                value={false}
                                                style={{
                                                    fontWeight: 'normal',
                                                    fontSize: 13,
                                                    width: 150,
                                                }}
                                            >
                                                No
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label="Amenities">
                                        <Select
                                            data-testid="amenities-select"
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            value={selectedAmenityValues}
                                            onChange={
                                                onSetSelectedAmenityValues
                                            }
                                            options={filteredAmenitiesOptions.map(
                                                (option) => ({
                                                    value: option,
                                                    label: option,
                                                }),
                                            )}
                                        />
                                    </Form.Item>

                                    <Form.Item label="Certifications">
                                        <Select
                                            data-testid="certifications-select"
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            value={selectedCertificationValues}
                                            onChange={
                                                onSetSelectedCertificationValues
                                            }
                                            options={filteredCertsOptions.map(
                                                (option) => ({
                                                    value: option,
                                                    label: option,
                                                }),
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </TabPane>

                    <TabPane
                        tab="Purchase Information"
                        key="acquisitionInformation"
                    >
                        <Form
                            layout={'horizontal'}
                            labelCol={{ span: 12 }}
                            labelAlign="left"
                            style={{ padding: 15 }}
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Purchase Price">
                                        <InputNumber
                                            data-testid="purchase-price-input"
                                            style={{ width: '150px' }}
                                            addonBefore={'$'}
                                            placeholder={'0'}
                                            defaultValue={clientPurchasePrice}
                                            onChange={(value) =>
                                                setClientPurchasePrice(
                                                    value ?? undefined,
                                                )
                                            }
                                            precision={2}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Purchase Date">
                                        <DatePicker
                                            data-testid="purchase-date-input"
                                            style={{ width: '150px' }}
                                            value={
                                                clientPurchaseDate
                                                    ? moment(clientPurchaseDate)
                                                    : null
                                            }
                                            onChange={(e) => {
                                                if (e) {
                                                    setClientPurchaseDate(
                                                        e.format('MM/DD/YYYY'),
                                                    );
                                                    return;
                                                }
                                                setClientPurchaseDate('');
                                            }}
                                            format={'MM/DD/YYYY'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Cap Rate (%)">
                                        <InputNumber
                                            data-testid="cap-rate-input"
                                            style={{ width: '150px' }}
                                            value={acquisitionCapRate ?? 0}
                                            defaultValue={
                                                acquisitionCapRate ?? 0
                                            }
                                            onChange={(value) =>
                                                setAcquisitionCapRate(
                                                    value ?? undefined,
                                                )
                                            }
                                            min={0}
                                            max={100}
                                            addonAfter={'%'}
                                            placeholder={'0%'}
                                            precision={2}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Discount Rate (%)">
                                        <InputNumber
                                            data-testid="discount-rate-input"
                                            style={{ width: '150px' }}
                                            min={0}
                                            max={100}
                                            addonAfter={'%'}
                                            placeholder={'0%'}
                                            precision={2}
                                            value={acquisitionDiscountRate}
                                            defaultValue={
                                                acquisitionDiscountRate
                                            }
                                            onChange={(value) =>
                                                setAcquisitionDiscountRate(
                                                    value ?? undefined,
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="UW IRR">
                                        <InputNumber
                                            data-testid="uw-irr-input"
                                            style={{ width: '150px' }}
                                            min={0}
                                            max={100}
                                            addonAfter={'%'}
                                            placeholder={'0%'}
                                            precision={2}
                                            value={uwIRR}
                                            onChange={(value) =>
                                                setUwIRR(value ?? undefined)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="UW Hold Period">
                                        <InputNumber
                                            data-testid="uw-hold-period-input"
                                            style={{ width: '150px' }}
                                            addonAfter={'Years'}
                                            placeholder={'0'}
                                            value={holdPeriod}
                                            precision={0}
                                            defaultValue={holdPeriod}
                                            onChange={(value) =>
                                                setHoldPeriod(
                                                    value ?? undefined,
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </TabPane>

                    <TabPane tab="Team" key="team">
                        <Form
                            labelCol={{ span: 10 }}
                            layout={'horizontal'}
                            labelAlign="left"
                            labelWrap
                            colon={false}
                            style={{ padding: 15 }}
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Portfolio Manager">
                                        <Select
                                            data-testid="portfolio-manager-select"
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            value={selectedPortfolioManager}
                                            onChange={(values: string[]) =>
                                                onSetTeamValues(
                                                    setSelectedPortfolioManager,
                                                    values,
                                                )
                                            }
                                            options={filterTeamOptions.map(
                                                (option) => ({
                                                    value: option,
                                                    label: option,
                                                }),
                                            )}
                                            onClear={() =>
                                                onSetTeamValues(
                                                    setSelectedPortfolioManager,
                                                    [],
                                                )
                                            }
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Asset Manager">
                                        <Select
                                            data-testid="asset-manager-select"
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            value={selectedAssetManager}
                                            onChange={(values: string[]) =>
                                                onSetTeamValues(
                                                    setSelectedAssetManager,
                                                    values,
                                                )
                                            }
                                            options={filterTeamOptions.map(
                                                (option) => ({
                                                    value: option,
                                                    label: option,
                                                }),
                                            )}
                                            onClear={() =>
                                                onSetTeamValues(
                                                    setSelectedAssetManager,
                                                    [],
                                                )
                                            }
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Analyst">
                                        <Select
                                            data-testid="analyst-select"
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            value={selectedAnalyst}
                                            onChange={(values: string[]) =>
                                                onSetTeamValues(
                                                    setSelectedAnalyst,
                                                    values,
                                                )
                                            }
                                            options={filterTeamOptions.map(
                                                (option) => ({
                                                    value: option,
                                                    label: option,
                                                }),
                                            )}
                                            onClear={() =>
                                                onSetTeamValues(
                                                    setSelectedAnalyst,
                                                    [],
                                                )
                                            }
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Property Manager">
                                        <Select
                                            data-testid="property-manager-select"
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            value={selectedPropertyManager}
                                            onChange={(values: string[]) =>
                                                onSetTeamValues(
                                                    setSelectedPropertyManager,
                                                    values,
                                                )
                                            }
                                            options={filterTeamOptions.map(
                                                (option) => ({
                                                    value: option,
                                                    label: option,
                                                }),
                                            )}
                                            onClear={() =>
                                                onSetTeamValues(
                                                    setSelectedPropertyManager,
                                                    [],
                                                )
                                            }
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Management Company">
                                        <Select
                                            data-testid="management-company-select"
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            value={selectedManagementCompany}
                                            onChange={(values: string[]) =>
                                                onSetTeamValues(
                                                    setSelectedManagementCompany,
                                                    values,
                                                )
                                            }
                                            options={filterTeamOptions.map(
                                                (option) => ({
                                                    value: option,
                                                    label: option,
                                                }),
                                            )}
                                            onClear={() =>
                                                onSetTeamValues(
                                                    setSelectedManagementCompany,
                                                    [],
                                                )
                                            }
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </TabPane>

                    {hasDebtAndValuePropertyInfo && (
                        <TabPane tab="Debt & Value" key="debt">
                            <Form
                                labelCol={{ span: 10 }}
                                layout={'horizontal'}
                                labelAlign="left"
                                labelWrap
                                style={{ maxHeight: '500px', padding: 15 }}
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="GAV Source">
                                            <Radio.Group
                                                onChange={(e) =>
                                                    setGavSource(e.target.value)
                                                }
                                                value={gavSource}
                                            >
                                                <Radio
                                                    value={
                                                        propertyProfileGavSources.BALANCE_SHEET
                                                    }
                                                    style={{
                                                        fontWeight: 'normal',
                                                        fontSize: 13,
                                                        width: 120,
                                                    }}
                                                >
                                                    Balance Sheet
                                                </Radio>
                                                <Radio
                                                    value={
                                                        propertyProfileGavSources.VALUATIONS
                                                    }
                                                    style={{
                                                        fontWeight: 'normal',
                                                        fontSize: 13,
                                                        width: 150,
                                                    }}
                                                >
                                                    Valuation Widget
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Levered">
                                            <Radio.Group
                                                onChange={(e) =>
                                                    setLevered(e.target.value)
                                                }
                                                value={levered}
                                            >
                                                <Radio
                                                    value={true}
                                                    style={{
                                                        fontWeight: 'normal',
                                                        fontSize: 13,
                                                        width: 120,
                                                    }}
                                                >
                                                    Yes
                                                </Radio>
                                                <Radio
                                                    value={false}
                                                    style={{
                                                        fontWeight: 'normal',
                                                        fontSize: 13,
                                                        width: 150,
                                                    }}
                                                >
                                                    No
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Divider
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 15,
                                        }}
                                    ></Divider>
                                    {levered && (
                                        <>
                                            <Col span={12}>
                                                <Form.Item label="Loan Type">
                                                    <Input
                                                        style={{
                                                            width: '200px',
                                                        }}
                                                        data-testid="loan-type-textarea"
                                                        defaultValue={loanType}
                                                        value={loanType}
                                                        onChange={(e) =>
                                                            setLoanType(
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Lender">
                                                    <Input
                                                        style={{
                                                            width: '200px',
                                                        }}
                                                        data-testid="lender-input"
                                                        defaultValue={lender}
                                                        value={lender}
                                                        onChange={(e) =>
                                                            setLender(
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Rate Type">
                                                    <Select
                                                        data-testid="rate-type-select"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                        value={clientRateType}
                                                        onChange={(
                                                            value: string,
                                                        ) =>
                                                            setClientRateType(
                                                                value,
                                                            )
                                                        }
                                                        options={
                                                            rateTypeOptions
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Maturity Date">
                                                    <DatePicker
                                                        data-testid="maturit-date-input"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                        value={
                                                            debtMaturityDate
                                                                ? moment(
                                                                      debtMaturityDate,
                                                                  )
                                                                : null
                                                        }
                                                        onChange={(e) => {
                                                            if (e) {
                                                                setDebtMaturityDate(
                                                                    e.format(
                                                                        'MM/DD/YYYY',
                                                                    ),
                                                                );
                                                                return;
                                                            }
                                                            setDebtMaturityDate(
                                                                '',
                                                            );
                                                        }}
                                                        format={'MM/DD/YYYY'}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {clientRateType ===
                                                CLIENT_RATE_TYPE_ENUM.FLOATING && (
                                                <>
                                                    <Col span={12}>
                                                        <Form.Item label="Floating Index">
                                                            <Input
                                                                style={{
                                                                    width: '150px',
                                                                }}
                                                                data-testid="floating-index-textarea"
                                                                defaultValue={
                                                                    floatingIndex
                                                                }
                                                                value={
                                                                    floatingIndex
                                                                }
                                                                onChange={(e) =>
                                                                    setFloatingIndex(
                                                                        e.target
                                                                            .value,
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label="Interest Rate Spread">
                                                            <InputNumber
                                                                data-testid="interest-rate-spread-input"
                                                                style={{
                                                                    width: '150px',
                                                                }}
                                                                min={0}
                                                                max={100}
                                                                addonAfter={'%'}
                                                                placeholder={
                                                                    '0%'
                                                                }
                                                                precision={4}
                                                                value={
                                                                    interestRateSpread
                                                                }
                                                                defaultValue={
                                                                    interestRateSpread
                                                                }
                                                                onChange={(
                                                                    value,
                                                                ) =>
                                                                    setInterestRateSpread(
                                                                        value ??
                                                                            0,
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            )}
                                            {clientRateType ===
                                                CLIENT_RATE_TYPE_ENUM.FIXED && (
                                                <Col span={12}>
                                                    <Form.Item label="Fixed Interest Rate">
                                                        <InputNumber
                                                            data-testid="fixed-interest-rate-input"
                                                            style={{
                                                                width: '150px',
                                                            }}
                                                            value={
                                                                fixedInterestRate
                                                            }
                                                            defaultValue={
                                                                fixedInterestRate
                                                            }
                                                            min={0}
                                                            max={100}
                                                            addonAfter={'%'}
                                                            placeholder={'0%'}
                                                            precision={4}
                                                            onChange={(value) =>
                                                                setFixedInterestRate(
                                                                    value ??
                                                                        undefined,
                                                                )
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            )}
                                        </>
                                    )}
                                </Row>
                            </Form>
                            {levered && (
                                <Form
                                    labelCol={{ span: 10 }}
                                    layout={'vertical'}
                                    labelAlign="left"
                                    labelWrap
                                    style={{ maxHeight: '500px', padding: 15 }}
                                >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item label="Debt Notes">
                                                <TextArea
                                                    data-testid="debt-notes-textarea"
                                                    rows={3}
                                                    maxLength={500}
                                                    showCount
                                                    defaultValue={debtNotes}
                                                    value={debtNotes}
                                                    onChange={(e) =>
                                                        setDebtNotes(
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="DSCR Default">
                                                <Radio.Group
                                                    onChange={(e) =>
                                                        setClientTimingPreferences(
                                                            e.target.value,
                                                        )
                                                    }
                                                    value={
                                                        clientTimingPreferences
                                                    }
                                                >
                                                    <Radio
                                                        value={'ytd'}
                                                        style={{
                                                            fontWeight:
                                                                'normal',
                                                            fontSize: 13,
                                                            width: 90,
                                                        }}
                                                    >
                                                        YTD
                                                    </Radio>
                                                    <Radio
                                                        value={'trailing_12'}
                                                        style={{
                                                            fontWeight:
                                                                'normal',
                                                            fontSize: 13,
                                                            width: 90,
                                                        }}
                                                    >
                                                        T-12
                                                    </Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </TabPane>
                    )}

                    <TabPane tab="Preferences" key="preferences">
                        <Form
                            layout={'horizontal'}
                            labelCol={{ span: 12 }}
                            labelAlign="left"
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Financial Year Ending">
                                        <Select
                                            data-testid="financial-year-ending-select"
                                            style={{ width: '150px' }}
                                            value={clientFinancialYearEnding}
                                            onChange={(e) =>
                                                setClientFinancialYearEnding(e)
                                            }
                                            options={monthsAsFinancialYearEndings()}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="KPI Preference">
                                        <Radio.Group
                                            onChange={(e) =>
                                                setClientKpiPreferences(
                                                    e.target.value,
                                                )
                                            }
                                            value={clientKpiPreferences}
                                        >
                                            <Radio
                                                value={'psf'}
                                                style={{
                                                    fontWeight: 'normal',
                                                    fontSize: 13,
                                                    width: 90,
                                                }}
                                            >
                                                Per SF
                                            </Radio>
                                            <Radio
                                                value={'punit'}
                                                style={{
                                                    fontWeight: 'normal',
                                                    fontSize: 13,
                                                    width: 90,
                                                }}
                                            >
                                                Per Unit
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </TabPane>
                </Tabs>
            </Modal>
        </>
    );
};

export default PropertyDetailsEditor;
