import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import React, { useImperativeHandle, useState } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import { getPureId } from 'state/routing/selectors';
import { usePropertyInformation } from 'waypoint-hooks';
import { selectPropertiesById } from 'state/properties/selectors';
import { PropertyType } from 'waypoint-types';
import { Divider, Empty, message } from 'antd';
import { DatePicker } from 'waypoint-react';
import {
    ResponsiveBox,
    Item,
    Location,
    Col,
    Row,
} from 'devextreme-react/responsive-box';
import PropertyProfileMap from 'components/propertyProfile/PropertyProfileMap';
import { css } from 'emotion';
import useSWR from 'swr';
import { API_URL } from 'config/constants';
import { EntityReportWidgetPdfSettings } from '../../ReportUtils';
import PropertyCoreInformation from 'components/propertyProfile/property-sections/PropertyCoreInformation';
import PropertyPurchaseInformation from 'components/propertyProfile/property-sections/PropertyPurchaseInformation';
import PropertySiteInformation from 'components/propertyProfile/property-sections/PropertySiteInformation';
import PropertyAmenitiesAndCertifications from 'components/propertyProfile/property-sections/PropertyAmenitiesAndCertifications';
import { AppFeaturePermissions, usePermissions } from 'contexts';
import PropertyValue from 'components/propertyProfile/property-sections/PropertyValue';
import PropertyTeam from 'components/propertyProfile/property-sections/PropertyTeam';
import PropertyDebt from 'components/propertyProfile/property-sections/PropertyDebt';
import PropertyDetailsEditor from 'components/propertyProfile/PropertyDetailsEditor';
import moment from 'moment';
import { CaretDownFilled } from '@ant-design/icons';
import theme from 'config/theme';

const datePickerStyle = css`
    padding: 0;
    cursor: pointer;
    .ant-picker-suffix {
        color: ${theme.colors.blues.antBlue};
    }
    .ant-picker-input {
        input {
            width: 110px;
            color: ${theme.colors.blues.antBlue};
            cursor: pointer;
        }
    }
`;

interface PropertyInformationProps {
    widgetId: string;
    widgetType: string;
    entityCode: string;
    widgetSettings: { [key: string]: any };
    propertyInfo?: PropertyType;
    isPDFExport?: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
}

const PROPERTY_INFO_HEIGHT = 235;

const entityImage = css`
    border-radius: 7px;
    object-fit: cover;
    position: relative;
    height: ${PROPERTY_INFO_HEIGHT}px;
`;

const labelStyle = { width: 160 };

export const PropertyInformationWidget = React.forwardRef<
    PDFExportable,
    PropertyInformationProps
>(
    (
        {
            widgetId,
            widgetType,
            entityCode,
            propertyInfo,
            widgetSettings,
            isPDFExport,
            pdfSettings,
        }: PropertyInformationProps,
        ref,
    ) => {
        const {
            includeMap,
            includePhoto,
            imgMapPosition,
            periodSelected,
            timing,
            debtAndValueEnabled,
            teamEnabled,
            showCoreInfo,
            showPurchaseInfo,
            showSiteInfo,
            showAmenities,
            showCertifications,
        } = widgetSettings;

        const [isImageLoaded, setIsImageLoaded] = useState(false);
        const [isMapLoaded, setIsMapLoaded] = useState(false);

        const selectedDataLevel = {
            stakeholder: null,
            percentageType: null,
        };
        const {
            entityPhoto,
            isLoadingPropertyDetails,
            selectedAmenityValues,
            selectedCertificationValues,
            levered,
            loanType,
            lender,
            debtNotes,
            debtMaturityDate,
            floatingIndex,
            interestRateSpread,
            fixedInterestRate,
            valueAndDebtData,
            clientRateType,
            clientKpiPreferences,
            leasePropertyData,
            clientTimingPreferences,
            asOfDate,
            gavSource,
        } = usePropertyInformation({
            entityCode,
            selectedDataLevel,
            propertyInfo,
            valueDebtPeriod: periodSelected,
            periodSelectedTiming: timing,
        });

        const { featurePermissions } = usePermissions();

        const widgetExportId = `narrativePDFWrapper_${widgetType}_${widgetId}`;

        const getEntityPhoto64 = async (entityCode: string) => {
            const response = await fetch(
                `${API_URL}/properties/photo/${entityCode}`,
                {
                    method: 'GET',
                    credentials: 'include',
                },
            );
            const { data } = await response.json();
            return data;
        };
        const {
            data: entityPhotoData,
            isValidating: isValidatingEntityPhotoData,
        } = useSWR(entityPhoto, () => getEntityPhoto64(entityCode));

        const isImageFinished =
            !includePhoto ||
            (includePhoto && isImageLoaded && !isValidatingEntityPhotoData) ||
            (!entityPhotoData && !isValidatingEntityPhotoData);
        const isMapFinished = !includeMap || (includeMap && isMapLoaded);

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return (
                    isImageFinished &&
                    isMapFinished &&
                    !isLoadingPropertyDetails
                );
            },
        }));

        if (!propertyInfo) {
            return <></>;
        }

        if (
            typeof propertyInfo?.latitude !== 'number' ||
            typeof propertyInfo?.longitude !== 'number'
        ) {
            return null;
        }

        const latitude = propertyInfo.latitude;
        const longitude = propertyInfo.longitude;

        const getImageBase64 = async (url: string) => {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };

        async function loadMapImage(
            latitude: number,
            longitude: number,
            mapSize: string,
        ) {
            const mapImageElement = document.getElementById(
                `mapImage_${widgetId}`,
            );
            if (!(mapImageElement instanceof HTMLImageElement)) {
                return;
            }

            try {
                const base64Image = await getImageBase64(
                    `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&markers=color:red|${latitude},${longitude}&zoom=14&size=${mapSize}&key=${window.___GOOGLE_API_KEY}`,
                );
                mapImageElement.src = `${base64Image}`;
            } catch (error) {
                message.error('Cannot load map to pdf.');
            }
        }

        if (isPDFExport) {
            const portraitMapSize = '380x253';
            const landscapeMapSize =
                pdfSettings?.paperSize === 'Letter' ? '510x338' : '750x438';
            const mapSize = pdfSettings?.landscape
                ? landscapeMapSize
                : portraitMapSize;
            loadMapImage(latitude, longitude, mapSize);
        }

        const getResponsiveItemLocations = ({
            imgMapPosition,
            includeMap,
            includePhoto,
        }: {
            imgMapPosition: string;
            includeMap: boolean;
            includePhoto: boolean;
        }) => {
            let descriptionRow,
                descriptionColspan,
                rowPosition,
                imageColumn,
                mapColumn,
                rowColspan;

            if (imgMapPosition === 'above') {
                descriptionRow = 1;
                rowPosition = 0;
            } else {
                descriptionRow = 0;
                rowPosition = 1;
            }

            if (!includeMap || !includePhoto) {
                imageColumn = 1;
                mapColumn = 1;
            } else {
                imageColumn = 0;
                mapColumn = 2;
            }
            descriptionColspan = 4;
            rowColspan = 2;

            return {
                descriptionRow,
                descriptionColspan,
                rowPosition,
                imageColumn,
                mapColumn,
                rowColspan,
            };
        };

        const responsiveItemLocations = getResponsiveItemLocations({
            imgMapPosition,
            includeMap,
            includePhoto,
        });

        const renderImage = () => {
            if (!entityPhotoData) {
                return (
                    <Empty
                        style={{
                            height: '80%',
                            width: '80%',
                            margin: '0 auto',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                        description="No image for this property"
                    />
                );
            }

            return (
                <div
                    style={{
                        overflow: 'hidden',
                        position: 'relative',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        className={entityImage}
                        data-testid="property-detail-photo"
                        src={`data:image/jpeg;base64,${entityPhotoData}`}
                        alt={`${propertyInfo.display_name} `}
                        style={{
                            margin: '0 auto',
                            objectFit: 'cover',
                            position: 'absolute',
                            height: '95%',
                            width: '95%',
                        }}
                        onLoad={() => setIsImageLoaded(true)}
                    />
                </div>
            );
        };

        function getBaseSize(
            imgMapPosition: 'above' | 'below',
            positionToCheck: 'above' | 'below',
            includePhoto: boolean,
            includeMap: boolean,
        ): number {
            if (!includePhoto && !includeMap) return 0;
            return imgMapPosition === positionToCheck ? 350 : 250;
        }

        const globalDescriptionsStyle = css`
            .ant-descriptions-header {
                margin-bottom: 8px;
                font-size: 18px;
            }
            .ant-descriptions-item-content {
                justify-content: flex-start;
            }
        `;

        const isVisible = (flag: boolean) => flag !== false;

        return (
            <div id={widgetExportId}>
                {!isPDFExport && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <h2
                            style={{
                                margin: '0 12px 0 0',
                                padding: 0,
                            }}
                        >
                            Property Information
                        </h2>
                        <PropertyDetailsEditor
                            selectedDataLevel={selectedDataLevel}
                            entityCode={entityCode}
                            propertyInfo={propertyInfo}
                            hasDebtAndValuePropertyInfo={!!debtAndValueEnabled}
                        />
                    </div>
                )}
                <ResponsiveBox>
                    <Row
                        ratio={1}
                        baseSize={getBaseSize(
                            imgMapPosition,
                            'above',
                            includePhoto,
                            includeMap,
                        )}
                    />
                    <Row
                        ratio={1}
                        baseSize={getBaseSize(
                            imgMapPosition,
                            'below',
                            includePhoto,
                            includeMap,
                        )}
                    />
                    <Col ratio={1} />
                    <Col ratio={1} />
                    <Col ratio={1} />
                    <Col ratio={1} />

                    <Item>
                        <Location
                            row={responsiveItemLocations.descriptionRow}
                            col={0}
                            colspan={responsiveItemLocations.descriptionColspan}
                        />
                        <div
                            className={globalDescriptionsStyle}
                            style={{
                                margin: '8px',
                                padding: '8px 16px',
                                borderTop: '1px solid lightgrey',
                                borderBottom: '1px solid lightgrey',
                            }}
                        >
                            {isVisible(showCoreInfo) ? (
                                <PropertyCoreInformation
                                    propertyInfo={propertyInfo}
                                    entityCode={entityCode}
                                    selectedDataLevel={selectedDataLevel}
                                />
                            ) : null}

                            {isVisible(showSiteInfo) ? (
                                <>
                                    <Divider
                                        style={{
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                        }}
                                    />

                                    <PropertySiteInformation
                                        propertyInfo={propertyInfo}
                                        entityCode={entityCode}
                                        selectedDataLevel={selectedDataLevel}
                                    />
                                </>
                            ) : null}

                            {isVisible(showAmenities) ||
                            isVisible(showCertifications) ? (
                                <>
                                    <Divider
                                        style={{
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                        }}
                                    />

                                    <PropertyAmenitiesAndCertifications
                                        selectedAmenityValues={
                                            selectedAmenityValues
                                        }
                                        selectedCertificationValues={
                                            selectedCertificationValues
                                        }
                                        isPDFExport={isPDFExport}
                                        labelStyle={labelStyle}
                                        showCertifications={isVisible(
                                            showCertifications,
                                        )}
                                        showAmenities={isVisible(showAmenities)}
                                    />
                                </>
                            ) : null}

                            {isVisible(showPurchaseInfo) ? (
                                <>
                                    <Divider
                                        style={{
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                        }}
                                    />
                                    <PropertyPurchaseInformation
                                        propertyInfo={propertyInfo}
                                        entityCode={entityCode}
                                        selectedDataLevel={selectedDataLevel}
                                    />{' '}
                                </>
                            ) : null}

                            {teamEnabled ? (
                                <>
                                    <Divider
                                        style={{
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                        }}
                                    />
                                    <PropertyTeam
                                        propertyInfo={propertyInfo}
                                        entityCode={entityCode}
                                        selectedDataLevel={selectedDataLevel}
                                    />
                                </>
                            ) : null}

                            {debtAndValueEnabled ? (
                                <>
                                    <Divider
                                        style={{
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                        }}
                                    />
                                    <PropertyValue
                                        entityCode={entityCode}
                                        periodSelected={periodSelected}
                                        propertyInfo={propertyInfo}
                                        valueAndDebtData={valueAndDebtData}
                                        asOfDate={asOfDate}
                                        clientKpiPreferences={
                                            clientKpiPreferences
                                        }
                                        leasePropertyData={leasePropertyData}
                                        gavSource={gavSource}
                                        selectedDataLevel={selectedDataLevel}
                                        title={
                                            <>
                                                <span>
                                                    Value
                                                    <span
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            As of:{' '}
                                                            {moment
                                                                .utc(
                                                                    !periodSelected
                                                                        ? asOfDate
                                                                        : periodSelected,
                                                                )
                                                                .format(
                                                                    'MMMM YYYY',
                                                                )}
                                                        </span>
                                                    </span>
                                                </span>
                                            </>
                                        }
                                    />
                                </>
                            ) : null}

                            {debtAndValueEnabled && levered ? (
                                <>
                                    <Divider
                                        style={{
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                        }}
                                    />
                                    <PropertyDebt
                                        loanType={loanType}
                                        lender={lender}
                                        debtNotes={debtNotes}
                                        debtMaturityDate={debtMaturityDate}
                                        floatingIndex={floatingIndex}
                                        interestRateSpread={interestRateSpread}
                                        fixedInterestRate={fixedInterestRate}
                                        valueAndDebtData={valueAndDebtData}
                                        clientTimingPreferences={
                                            clientTimingPreferences
                                        }
                                        clientRateType={clientRateType}
                                        title={
                                            <>
                                                <span>
                                                    Debt
                                                    <span
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            As of:{' '}
                                                            {moment
                                                                .utc(
                                                                    !periodSelected
                                                                        ? asOfDate
                                                                        : periodSelected,
                                                                )
                                                                .format(
                                                                    'MMMM YYYY',
                                                                )}
                                                        </span>
                                                    </span>
                                                </span>
                                            </>
                                        }
                                    />
                                </>
                            ) : null}
                        </div>
                    </Item>
                    {includePhoto && (
                        <Item visible={includePhoto}>
                            <Location
                                row={responsiveItemLocations.rowPosition}
                                col={responsiveItemLocations.imageColumn}
                                colspan={responsiveItemLocations.rowColspan}
                            />
                            <div
                                style={{
                                    margin: '8px',
                                    height: '100%',
                                }}
                            >
                                {renderImage()}
                            </div>
                        </Item>
                    )}
                    {includeMap && (
                        <Item visible={includeMap}>
                            <Location
                                row={responsiveItemLocations.rowPosition}
                                col={responsiveItemLocations.mapColumn}
                                colspan={responsiveItemLocations.rowColspan}
                            />
                            <div
                                style={{
                                    margin: '8px',
                                    height: '100%',
                                }}
                            >
                                {isPDFExport ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '100%',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            id={`mapImage_${widgetId}`}
                                            alt={`${propertyInfo.display_name} Map`}
                                            style={{
                                                margin: '0 auto',
                                                borderRadius: 7,
                                                objectFit: 'cover',
                                                position: 'absolute',
                                                height: '95%',
                                                width: '95%',
                                            }}
                                            onLoad={() => setIsMapLoaded(true)}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '100%',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                margin: '0 auto',
                                                borderRadius: 7,
                                                objectFit: 'cover',
                                                position: 'absolute',
                                                height: '95%',
                                                width: '95%',
                                            }}
                                        >
                                            <PropertyProfileMap
                                                entityCode={entityCode}
                                                height={'100%'}
                                                widgetProperty={propertyInfo}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Item>
                    )}
                </ResponsiveBox>
            </div>
        );
    },
);

const mapState = (s: RootStateOrAny) => {
    const entityCode = getPureId(s);
    const properties = selectPropertiesById(s, { propertyIds: [entityCode] });

    return {
        propertyInfo: properties[Object.keys(properties)[0]],
    };
};

export default connect(mapState)(PropertyInformationWidget);
