import { Button, Divider, Select, Space, message } from 'antd';
import { EditValuationModal } from 'components/valuations/EditValuationModal';
import { format } from 'date-fns-tz';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useGetPropertyValuation, useGetValuations } from 'waypoint-hooks';
import {
    createValuation,
    updateOrInsertPropertyValuation,
} from 'waypoint-requests';
import { PropertyType, Valuation } from 'waypoint-types';
import { formatMoney } from 'utils/formatters';
import { css } from 'emotion';
import { DownOutlined } from '@ant-design/icons';

interface ValuationsTableProps {
    entityCode: string;
    valuationSelected?: Valuation;
    property: PropertyType;
    periodSelected: Date | undefined;
    isPDFExport?: boolean;
    setSelectedValuations?: (selectedValuations: number) => void;
}

interface ClientSelectableOptions {
    value: string;
    label: string;
}

interface OptionGroup {
    label: string;
    options: {
        label: string;
        value: string;
    }[];
}

interface GroupedOptions {
    [key: string]: OptionGroup;
}

const selectorStyle = css`
    .ant-select-selector {
        padding-left: 0 !important;
    }
`;

const ValuationGrossValueButton = ({
    entityCode,
    valuationSelected,
    property,
    periodSelected,
    isPDFExport,
    setSelectedValuations,
}: ValuationsTableProps) => {
    const { data, mutate } = useGetValuations(entityCode);
    const { data: propertyValuations, mutate: mutateValuation } =
        useGetPropertyValuation(entityCode);
    const [isEditModalVisible, setIsEditModalVisible] =
        useState<boolean>(false);

    const [methodOptions, setMethodOptions] = useState<
        ClientSelectableOptions[]
    >([]);

    const { valuations, methods } = data ?? { valuations: [], methods: [] };

    useEffect(() => {
        if (methods) {
            const methodOptions = methods?.map((type) => {
                return {
                    value: type,
                    label: type,
                };
            });
            setMethodOptions(methodOptions);
        }
    }, [methods]);

    if (!data || !periodSelected) {
        return <></>;
    }

    const filteredValuationsByDate = data?.valuations?.filter((item) => {
        return (
            format(new Date(item.date), 'yyyy-MM') ===
            format(new Date(periodSelected), 'yyyy-MM')
        );
    });

    const propertyValuationsForThePeriod = propertyValuations?.filter(
        (item) => {
            return (
                format(new Date(item.period), 'yyyy-MM') ===
                format(new Date(periodSelected), 'yyyy-MM')
            );
        },
    );

    const valuationForThePeriod =
        propertyValuations?.find((item) => {
            return (
                format(new Date(item.period), 'yyyy-MM') ===
                format(new Date(periodSelected), 'yyyy-MM')
            );
        })?.valuation_id ?? null;

    const savePropertyValuation = async (valuationId: string) => {
        const selectedValuation = valuations.find(
            (item) => item.id === valuationId,
        );

        setSelectedValuations &&
            setSelectedValuations(Number(selectedValuation?.value) ?? 0);

        mutateValuation(
            async () => {
                try {
                    await updateOrInsertPropertyValuation({
                        entity_code: entityCode,
                        valuation_id: selectedValuation?.id ?? '',
                        period: selectedValuation?.date ?? '',
                    });

                    message.success(`Valuation added to property successfully`);

                    return propertyValuations;
                } catch (e) {
                    message.error(`Failed to add valuation to the property`);

                    return propertyValuations;
                }
            },
            {
                optimisticData: propertyValuations,
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            },
        );
    };

    const generateOptions = (dataArray: Valuation[]): OptionGroup[] => {
        const groupedOptions = dataArray.reduce<GroupedOptions>((acc, item) => {
            const groupKey =
                item.source.charAt(0).toUpperCase() + item.source.slice(1);

            const optionLabel = `${format(
                new Date(item.date),
                'MMMM yyyy',
            )} | ${formatMoney(item.value)}`;

            const option = {
                label: optionLabel,
                value: item.id,
            };

            if (!acc[groupKey]) {
                acc[groupKey] = {
                    label: groupKey,
                    options: [],
                };
            }

            acc[groupKey].options.push(option);

            return acc;
        }, {});

        return Object.values(groupedOptions);
    };

    const onSaveValuation = async (valuation: Valuation) => {
        valuation.value = Number(valuation.value);
        valuation.date = moment(valuation.date)
            .startOf('month')
            .format('YYYY-MM-DD hh:mm:ss');

        mutate(
            async () => {
                try {
                    await createValuation(valuation);

                    message.success(`Valuation added successfully`);

                    return {
                        valuations,
                        methods,
                    };
                } catch (e) {
                    message.error(`Failed add valuation`);
                    return {
                        valuations,
                        methods,
                    };
                }
            },
            {
                optimisticData: {
                    valuations,
                    methods: data.methods,
                },
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            },
        );
    };

    const renderEditValuationModal = () => {
        if (!isEditModalVisible) {
            return null;
        }

        const selectedRowData = {
            entity_code: entityCode,
            date: moment(periodSelected)
                .endOf('month')
                .format('YYYY-MM-DD hh:mm:ss'),
        } as Valuation;

        return (
            <EditValuationModal
                disabledDateField
                onlyToSave
                selectedRowData={selectedRowData}
                isVisible={isEditModalVisible}
                setIsVisible={setIsEditModalVisible}
                propertyOptions={[{ value: property.id, label: property.name }]}
                methodOptions={methodOptions}
                onClose={() => setIsEditModalVisible(false)}
                onSaved={onSaveValuation}
            />
        );
    };

    const renderSelectComponent = () => {
        const selectedValue = () => {
            const valuation = valuationForThePeriod
                ? valuations.find((item) => item.id === valuationForThePeriod)
                      ?.value ?? null
                : null;

            if (valuation && setSelectedValuations) {
                setSelectedValuations(Number(valuation));
            }

            return valuation ? formatMoney(valuation) : null;
        };

        if (
            filteredValuationsByDate?.length ||
            propertyValuationsForThePeriod?.length
        ) {
            return (
                <Select
                    size="small"
                    placeholder="Select a valuation"
                    bordered={false}
                    dropdownStyle={{ width: '300px' }}
                    popupMatchSelectWidth={false}
                    options={generateOptions(filteredValuationsByDate ?? [])}
                    onChange={(value) =>
                        savePropertyValuation(value.toString())
                    }
                    value={selectedValue()}
                    className={selectorStyle}
                    suffixIcon={isPDFExport ? null : <DownOutlined />}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <Space>
                                <Button
                                    onClick={() => setIsEditModalVisible(true)}
                                    type="link"
                                    size="small"
                                    style={{ paddingLeft: 6, paddingTop: 6 }}
                                >
                                    Add Valuation
                                </Button>
                            </Space>
                        </>
                    )}
                />
            );
        }
        return null;
    };

    if (isPDFExport && !valuationForThePeriod) {
        return <div />;
    }

    return (
        <>
            {renderEditValuationModal()}
            {!filteredValuationsByDate?.length &&
            !propertyValuationsForThePeriod?.length ? (
                <Button
                    style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
                    onClick={() => setIsEditModalVisible(true)}
                    type="link"
                >
                    Add Valuation
                </Button>
            ) : null}
            {renderSelectComponent()}
        </>
    );
};

export default ValuationGrossValueButton;
